import React, {useEffect, useState} from "react";
import {LocalizedLink} from "../commonComponents";
const SearchTabData = ({ groupByResult, activeTab, setActiveTab, setFilters, searchParam }) => {

  const tabClick = (key) => {
    setActiveTab(key);
  }
  useEffect(() => {
    let tabsLengthWidth = Object.keys(groupByResult).length * 14
    document.getElementsByClassName("ul-width")[0].style.width = `${tabsLengthWidth}%`
  },[searchParam])
  return (
    <div className="tab-box-search tw-overflow-x-auto tw-mb-7 tw-overflow-y-scroll tw-mt-[52px] md:tw-mb-10 md:tw-overflow-y-auto md:tw-mt-0" id="search">
      <ul className="is-capitalized ul-width tx-w-[300%] md:tx-w-[150%] -tw-mt-[18px]">
        <li><LocalizedLink className={activeTab === "All" ? "active tw-pl-0": "tw-pl-0"} onClick={() => tabClick("All")}>All</LocalizedLink></li>
        {groupByResult?.["Products"] && <li><LocalizedLink className={activeTab === "Products" ? "active": ""} onClick={() => tabClick("Products")}>Products</LocalizedLink></li>}
        {groupByResult?.["solutions"] && <li><LocalizedLink className={activeTab === "solutions" ? "active": ""} onClick={() => tabClick("solutions")}>Solutions</LocalizedLink></li>}

        {Object.keys(groupByResult).map((key, index) => {
          if (key !== "All" && key !== "Products" && key !== "Others" && key !== "solutions") {
            return (
              <li>
                <LocalizedLink className={activeTab === key ? "active": ""} onClick={() => tabClick(key)}>{key}</LocalizedLink>
              </li>
            )
          }

        })}
        {groupByResult?.["Others"] && <li><LocalizedLink className={activeTab === "Others" ? "active": ""} onClick={() => tabClick("Others")}>Others</LocalizedLink></li>}

      </ul>
    </div>
  )
}
export default SearchTabData;